<template>
  <div>
    <b-row class="form-box">
      <b-col md="12">
        <b-nav tabs class="form-section">
          <b-nav-item disabled>Reviewer Status</b-nav-item>
        </b-nav>
      </b-col>
      <b-col
        :md="item.md || 6"
        v-for="(item, index) in initData"
        :key="index"
        :class="{
          'my-1': item.type != null && item.text != null
        }"
      >
        <!-- 左右结构 -->
        <template v-if="item.type == null && item.text == null">
          <b-row v-for="(x, y) in item" :key="y">
            <div class="my-1 col">
              <b-form-group
                :label="x.text"
                :id="x.id"
                :label-for="'form-' + index + y"
                class="search-flex"
                v-bind:class="[{ required: x.required }, x.class]"
                label-cols-sm="4"
                label-cols-lg="3"
              >
                <!-- input -->
                <template v-if="x.type == 'input'">
                  <b-input-group :class="{ no_r: !x.icon }">
                    <template v-slot:append>
                      <b-input-group-text v-if="x.icon">
                        <b-icon :icon="x.icon"></b-icon>
                      </b-input-group-text>
                    </template>

                    <b-form-input
                      v-model="x.model"
                      :type="x.input_type || 'text'"
                      :min="x.min"
                      :max="x.max"
                      :placeholder="
                        x.disabled
                          ? ''
                          : x.placeholder || $t('page.please_choose_input')
                      "
                      :disabled="x.disabled"
                    ></b-form-input>
                  </b-input-group>
                </template>
                <!-- img -->
                <template v-if="x.type == 'img'">
                  <div>
                    <img
                      @click="showImg(x.url)"
                      v-if="x.url != null && x.url != ''"
                      class="image pointer"
                      :src="x.url"
                      alt="image"
                    />
                    <img
                      v-else
                      alt="edit"
                      class="image pointer"
                      src="@/assets/media/users/default.png"
                    />
                  </div>
                </template>
              </b-form-group>
            </div>
          </b-row>
        </template>
        <b-form-group
          v-else-if="item.show != false && item.type != null"
          :label="item.text"
          :id="item.id"
          :label-for="'form-' + index"
          class="search-flex"
          v-bind:class="[{ required: item.required }, item.class]"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <!-- input -->
          <template v-if="item.type == 'input'">
            <b-input-group :class="{ no_r: !item.icon }">
              <template v-slot:append>
                <b-input-group-text
                  v-if="item.icon || item.icon_text || item.icon_color"
                >
                  <b-icon v-if="item.icon" :icon="item.icon"></b-icon>
                  {{ item.icon_text }}
                  <div
                    v-if="item.icon_color && item.icon_color.indexOf('#') >= 0"
                    class="color-box"
                    :style="'background:' + item.icon_color"
                  ></div>
                  <div
                    v-if="item.icon_color && item.icon_color.indexOf('#') < 0"
                    class="color-box"
                    :style="
                      `background:url(${item.icon_color});background-size: 100%;`
                    "
                  ></div>
                </b-input-group-text>
              </template>

              <b-form-input
                v-model="item.model"
                :type="item.input_type || 'text'"
                :min="item.min"
                :max="item.max"
                :placeholder="
                  item.disabled
                    ? ''
                    : item.placeholder || $t('page.please_choose_input')
                "
                :disabled="item.disabled"
              ></b-form-input>
            </b-input-group>
          </template>
          <!-- 下拉框 -->
          <template v-else-if="item.type == 'select'">
            <SelectLoading v-if="item.options == 0" :size="0.7"></SelectLoading>
            <sv-select
              v-model="item.model"
              :disabled="item.options == 0 || item.disabled"
              :placeholder="
                item.disabled
                  ? ''
                  : item.placeholder || $t('page.please_choose')
              "
              :options="item.options"
              :reduce="options => options.value"
              :multiple="item.multiple"
              :selectable="options => !options.selectable"
              label="label"
            >
              <template slot="option" slot-scope="option" v-if="option.index">
                <div class="d-center" :class="'level_' + option.index">
                  <span v-if="option.index == 2">--</span>
                  {{ option.label }}
                </div>
              </template>
            </sv-select>
          </template>

          <template v-if="item.type == 'textarea'">
            <b-form-textarea
              :id="item.id"
              :rows="item.rows"
              :placeholder="
                item.disabled
                  ? ''
                  : item.placeholder || $t('page.please_choose_textarea')
              "
              :disabled="item.disabled"
              v-model="item.model"
            ></b-form-textarea>
          </template>
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal
      v-model="imageModal"
      centered
      no-close-on-backdrop
      hide-footer
      scrollable
      title="Image"
      id="image-box"
      ref="modal"
      size="lg"
    >
      <!-- <div class="showImg"> -->
      <img v-if="showImgUrl != null" :src="showImgUrl" alt="" />

      <!-- </div> -->
      <!-- <template v-slot:modal-footer="{ close }">
        <b-button size="sm" variant="secondary" @click="close()">{{
          $t('page.close')
        }}</b-button>
      </template> -->
    </b-modal>
  </div>

  <!-- <Others></Others> -->
</template>

<script>
// import ReferenceLink from '@/components/ReferenceLink/Index';
import SelectLoading from '@/components/Loading/Index.vue';
// import MerchantPartner from '@/views/content/Detail/Information/MerchantPartner';
// import Others from '@/views/content/Detail/Information/Others';
export default {
  name: 'ArticleReviewReviewerStatus',

  data() {
    return {
      showImgUrl: null,
      imageModal: false
    };
  },
  props: {
    initData: Object,
    options: Object
  },
  inheritAttrs: false,
  components: {
    // ReferenceLink
    SelectLoading
    // MerchantPartner
    // Others
  },
  methods: {
    showImg(url) {
      this.imageModal = true;
      this.showImgUrl = url;
    },
    initialTime(obj, name) {
      console.log(obj, name);
      obj[name] = null;
    }
  },

  computed: {},
  mounted() {}
};
</script>
<style scoped>
.image-box .bv-no-focus-ring {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  max-width: 100%;
  max-height: 170px;
}
</style>
